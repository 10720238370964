import React from 'react';

const FeedbackCard = ({imgUrl, feedback, name}) => {
  return (
    <div className="feedback-card">
      <span>“</span>
      <div className="flex">
        <p>{feedback}</p>
        <img src={imgUrl} />
        <h4>{name}</h4>
      </div>
    </div>
  )
}

export default FeedbackCard