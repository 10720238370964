import React, { useState } from 'react';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import star from '../images/star.svg';
import btnPencil from '../images/btn-pencil.svg';
import explorerStep1 from '../images/explorer-step1.svg';
import explorerStep2 from '../images/explorer-step2.svg';
import explorerStep3 from '../images/explorer-step3.svg';
import turningStep1 from '../images/turning-step1.svg';
import turningStep2 from '../images/turning-step2.svg';
import profileImg1 from '../images/profile-img1.svg';
import profileImg2 from '../images/profile-img2.svg';
import partner1 from '../images/partners/partner1.png';
import partner7 from '../images/partners/partner7.png';
import partner3 from '../images/partners/partner3.png';
import partner2 from '../images/partners/partner2.png';
import pencilPanelLogo from '../images/pencil-panel-logo.svg';
import pencilPanel from '../images/pencil-panel.svg';
import pencilPanelFooter from '../images/pencil-panel-footer.svg';
import spacex from '../images/websites/spacex.svg';
import khan from '../images/websites/khan.svg';
import ted from '../images/websites/ted.svg';
import bbc from '../images/websites/bbc.svg';
import mckinsey from '../images/websites/mckinsey.svg';
import wolfram from '../images/websites/wolfram.svg';
import btnSpacex from '../images/website-buttons/spacex.svg';
import btnSpacexWhite from '../images/website-buttons/spacex-white.svg';
import btnKhan from '../images/website-buttons/khan.svg';
import btnKhanWhite from '../images/website-buttons/khan-white.svg';
import btnTed from '../images/website-buttons/ted.svg';
import btnTedWhite from '../images/website-buttons/ted-white.svg';
import btnBBC from '../images/website-buttons/bbc.svg';
import btnBBCWhite from '../images/website-buttons/bbc-white.svg';
import btnMckinsey from '../images/website-buttons/mckinsey.svg';
import btnMckinseyWhite from '../images/website-buttons/mckinsey-white.svg';
import btnWolfram from '../images/website-buttons/wolfram.svg';
import btnWolframWhite from '../images/website-buttons/wolfram-white.svg';
import btnPencilWhite from '../images/btn-pencil-white.svg';

import FeedbackCard from '../components/Card';

const getWebsiteImage = (website) => {
  switch (website) {
    case 'spacex':
      return spacex
    case 'khan':
      return khan
    case 'ted':
      return ted
    case 'bbc':
      return bbc
    case 'mckinsey':
      return mckinsey
    case 'wolfram':
      return wolfram
  }
}

const getButtons = (website) => [
  {
    website: 'spacex',
    image: website === 'spacex' ? btnSpacex : btnSpacexWhite,
  },
  {
    website: 'khan',
    image: website === 'khan' ? btnKhan : btnKhanWhite,
  },
  {
    website: 'ted',
    image: website === 'ted' ? btnTed : btnTedWhite,
  },
  {
    website: 'bbc',
    image: website === 'bbc' ? btnBBC : btnBBCWhite,
  },
  {
    website: 'mckinsey',
    image: website === 'mckinsey' ? btnMckinsey : btnMckinseyWhite,
  },
  {
    website: 'wolfram',
    image: website === 'wolfram' ? btnWolfram : btnWolframWhite,
  },
]

const Explorer = () => {
  const [showPencilPanel, setShowPencilPanel] = useState(false)
  const [website, setWebsite] = useState('spacex')

  return (
    <Layout color="rgba(255, 255, 255, 0.75)" page="explorer">
      <SEO title="Explorer" />
      <section className="first-section">
        <h1 className="title">Why search when you can explore?</h1>
        <div className="button-section">
          <p>Find a path to where you’re going, from wherever you happen to be</p>
          <button className="btn-add-chrome"><strong>Add to Chrome</strong> it's free</button>
          <div className="star-section">
            <span>100+ Reviews</span>
            <img src={star} />
            <img src={star} />
            <img src={star} />
            <img src={star} />
            <img src={star} />
          </div>
        </div>
        <div className="website-view">
          <div className="website-view-header">
            <span className="btn-icon"></span>
            <span className="btn-icon"></span>
            <span className="btn-icon"></span>
            {
              getButtons(website).map((button, index) => (
                <img 
                  src={button.image} 
                  key={button.website} 
                  className="btn-website" 
                  onClick={() => setWebsite(button.website)} 
                  style={{zIndex: button.website === website ? 10 : 6 - index}}
                />
              ))
            }
            <img src={btnPencilWhite} style={{marginLeft: 20}} />
          </div>
          <div className="website-content">
            <img src={getWebsiteImage(website)} />
            {
              showPencilPanel ?
              <div className="pencil-panel">
                <div className="pencil-panel-header">
                  <img src={pencilPanelLogo} />
                  <span className="btn-close-panel" onClick={() => setShowPencilPanel(false)}>&times;</span>
                </div>
                <div className="pencil-panel-content">
                  <img src={pencilPanel} />
                </div>
                <img className="pencil-panel-footer" src={pencilPanelFooter} />
              </div> :
              <img className="btn-pencil" src={btnPencil} onClick={() => setShowPencilPanel(true)} />
            }
          </div>
        </div>
        <h2 className="title">Start Exploring in 2 Clicks</h2>
        <div className="install-container">
          <div className="install-step">
            <span className="step-number">1</span>
            <p>Install the free chrome extension</p>
            <img src={explorerStep1} />
          </div>
          <div className="install-step">
            <span className="step-number">2</span>
            <p>Click the Pencil tab on any website</p>
            <img src={explorerStep2} />
          </div>
          <div className="install-step">
            <span className="step-text">Done!</span>
            <p>Recieve related content as you browse!</p>
            <img src={explorerStep3} />
          </div>
        </div>
      </section>
      <section className="second-section">
        <div className="max-content">
          <h2 className="title">Going deeper only takes one click</h2>
          <p>Pencil automatically finds helpful resources based on what you’re viewing</p>
          <div className="step step1">
            <div className="step-content">
              <span>1.</span>
              <div>
                <h3>The best information in any form</h3>
                <p>Pencil searches content from the world’s best publishers and most trusted educators to bring you articles, videos, and interactive simulations on any topic.</p>
              </div>
            </div>
            <img src={turningStep1} />
          </div>
          <div className="step step2">
            <img src={turningStep2} />
            <div className="step-content">
              <span>2.</span>
              <div>
                <h3>The evolution of the “reading list”</h3>
                <p>Bookmark and save any type of content for later. You can access it anytime, or Pencil will remind you about it when it’s relevant to something you’re already looking at.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="third-section">
        <div className="max-content">
          <div className="card-section">
            <h2 className="title">Pencil Explore has changed the way people work.</h2>
            <div className="carousel-container">
              <FeedbackCard 
                name="Grey Harold, Teacher and Educator" 
                feedback="Every teacher I know has started using Pencil. Find one thing you like and bam, you have a whole class’ worth of material without any extra effort." 
                imgUrl={profileImg1} 
              />
              <FeedbackCard 
                name="Susan Smith, Student" 
                feedback="Pencil is like going down a Youtube rabbit hole, but for what I’m actually supposed to be learning. Which is honestly pretty great." 
                imgUrl={profileImg2} 
              />
              <div className="carousel-buttons">
                <span className="btn-carousel active"></span>
                <span className="btn-carousel"></span>
                <span className="btn-carousel"></span>
              </div>
            </div>
          </div>
          <div className="partners">
            <span>Built by Engineers from: </span>
            <img src={partner1} />
            <img src={partner7} />
            <img src={partner3} />
            <img src={partner2} />
          </div>
          <div className="btn-container">
            <h2 className="title">Start Exploring</h2>
            <p>The ultimate research assistant at your fingertips.</p>
            <button className="btn-add-chrome"><strong>Add to Chrome</strong> it's free</button>
          </div>
        </div>
      </section>
      <div className="border"></div>
    </Layout>
  );
}

export default Explorer;